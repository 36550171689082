
.green-text {
    color:  #00603a;
    font-weight: bold;
    font-size: 1.25rem;
}
.blue-text {
    color:  #00447c;
    /* font-weight: bold; */
    font-size: 1.25rem;
}
.big-blue-text {
    color:  #00447c;
    font-weight: 500;
    font-size: 2.25rem;
    margin-top: -30px;
    margin-bottom: 20px;
}
.billing-master-label-format {
    font-size: 1.25rem;
}
.billing-master-input-format {
    width: 90%;
    padding: 6px 8px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 1.5px 2px 2.5px 0px #00447c;
    white-space: pre-wrap;

}
.section-divider {
    color:  #00447c;
    font-weight: 700;
    font-size: 1.25rem;
    text-align: center;
    border-bottom: solid 2px #00447c;

}
