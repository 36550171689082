.header-container {
    border-bottom: solid !important;
    border-color: #00447c !important;
    border-width: .5px;
}
.justify-content-end {
    padding-right: 80px;
}
.logo {
    margin-left: 10px;
    padding-left: 40px;
    max-width: 240px;
    min-width: 240px;
}
.navbar {
    margin-top: -24px !important;
    /* padding-bottom: -15px !important; */
}
.navbar-brand {
    margin-bottom: -22px !important;

}
.navbar-toggler {
    border: none !important;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    box-shadow: none !important;
    border-style: solid !important;
    border-width: 2px !important;
    border-color: #00447c !important;
    background-color: none !important;
}

.blue-button-header {
    white-space: nowrap;
    display: inline-block !important;
    margin-top: 50px !important;
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
    border-radius: 8px !important;
    background-color: #00447c !important;
    font-weight: 500 !important;
    text-align: center !important;
    max-width: 200px !important;
  }
  .blue-button-header:hover {
    background-color: #fed000 !important;
    color: #000;
    font-weight: 600;
  }

@media (max-width: 1200px) {
    .justify-content-end {
        padding-right: 50px;
    }

   .logo {
       margin-left: 50px;
       padding-left: 25px;
       max-width: 200px;
       min-width: 200px;
   }
 }

