
/* This is from UE Host app repository.  Used for styling in Host Previews. */

.host-photo-more {
    border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;
    align-self: center;
    position: relative;
    max-width: 70%;
    height: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.more-about-alignment {
    text-align: left;
    align-items: left;
}
.small-print {
    font-size: .8rem;
}
.ue-icon {
    width: 30px;
    height: 30px;
}
.public-icon {
    width: 30px;
    height: 30px;
}
.client-rating-text {
    padding-top: 2rem;
    font-size: 1.5rem;
}
.host-message {
    text-align: wrap;
}
.resume-icon {
    border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;
    -moz-box-shadow: none;
    -goog-ms-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* border-color: black;
    border-style: solid; */
    width: 45px;
    height: 45px;
    padding-top: -5px;
    margin-top: -5px;
    margin-left: -8px;
    margin-right: 14px;
}
.email-icon {
    /* margin-top: 25px; */
    margin-left: -12px;
    width: 50px;
    height: 50px;
    background-size: 5px 5px;
    /* viewBox: "0 0 50 50"; */
}
@media (max-width: 992px) {
    .host-photo-more {
        max-width: 80%;
    }
    .client-rating-text {
        padding-top: 0rem;
        font-size: 1.1rem;
    }
}
