.modal-header {
    background-color: #00447c;
}

.modal-title {
    padding-top: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-width: 5px !important;
    border-top: 5px !important;
    border-bottom: 5px;
    border-color: #00447c !important;
    outline-width: 5px !important;
    outline-color: #00447c !important;
    font-size: 2.5rem !important;
    font-weight: 600;
    color: #00447c;
    text-align: center !important;
}
.modal-text {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 50px;
    font-size: 1.25rem;
    justify-content: center;
    align-items: center;
    display: flex;
    border-width: 5px !important;
    border-top: 5px !important;
    border-bottom: 5px;
    border-color: #00447c !important;
    outline-width: 5px !important;
    outline-color: #00447c !important;

}
.modal-body {
    border-bottom: 0 none !important;
}
.modal-footer {
    border: 0 none !important;
    outline: none !important;
    display: flex !important;
    position: relative;
    margin-bottom: 20px;
    /* padding-top: -100px !important; */
    height: 120px;
    margin-top: -60px;
    /* margin-top: -100px !important; */
    /* justify-content: center !important;
    align-items: center !important; */
}
.yellow-header{
    background-color: #fed000 !important;
}
.blue-modal-button {
    white-space: nowrap;
    display: inline-block !important;
    margin-top: -10px !important;
    margin-bottom: -10px !important;
    margin-left: 0px !important;
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
    border-radius: none !important;
    box-shadow: none !important;
    outline: none !important;
    /* outline-color: #00447c !important; */
    background-color: #00447c !important;
    font-weight: 500 !important;
    text-align: center !important;
    max-width: 400px !important;
    /* min-width: 100px !important; */
  }