@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&display=swap);
html {
  font-family: Montserrat, sans-serif;
  background-color: transparent;
}
body {
    font-family: "Montserrat";
    /* -apple-system, BlinkMacSystemFont; */
    color:#1e2022;
    background-color: white;
    text-align: center;
    width: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat",sans-serif;
  font-style: bold;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.App {
  text-align: center;
}


.header-container {
    border-bottom: solid !important;
    border-color: #00447c !important;
    border-width: .5px;
}
.justify-content-end {
    padding-right: 80px;
}
.logo {
    margin-left: 10px;
    padding-left: 40px;
    max-width: 240px;
    min-width: 240px;
}
.navbar {
    margin-top: -24px !important;
    /* padding-bottom: -15px !important; */
}
.navbar-brand {
    margin-bottom: -22px !important;

}
.navbar-toggler {
    border: none !important;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    box-shadow: none !important;
    border-style: solid !important;
    border-width: 2px !important;
    border-color: #00447c !important;
    background-color: none !important;
}

.blue-button-header {
    white-space: nowrap;
    display: inline-block !important;
    margin-top: 50px !important;
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    justify-content: flex-end !important;
    align-items: stretch !important;
    border-radius: 8px !important;
    background-color: #00447c !important;
    font-weight: 500 !important;
    text-align: center !important;
    max-width: 200px !important;
  }
  .blue-button-header:hover {
    background-color: #fed000 !important;
    color: #000;
    font-weight: 600;
  }

@media (max-width: 1200px) {
    .justify-content-end {
        padding-right: 50px;
    }

   .logo {
       margin-left: 50px;
       padding-left: 25px;
       max-width: 200px;
       min-width: 200px;
   }
 }


.modal-header {
    background-color: #00447c;
}

.modal-title {
    padding-top: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-width: 5px !important;
    border-top: 5px !important;
    border-bottom: 5px;
    border-color: #00447c !important;
    outline-width: 5px !important;
    outline-color: #00447c !important;
    font-size: 2.5rem !important;
    font-weight: 600;
    color: #00447c;
    text-align: center !important;
}
.modal-text {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 50px;
    font-size: 1.25rem;
    justify-content: center;
    align-items: center;
    display: flex;
    border-width: 5px !important;
    border-top: 5px !important;
    border-bottom: 5px;
    border-color: #00447c !important;
    outline-width: 5px !important;
    outline-color: #00447c !important;

}
.modal-body {
    border-bottom: 0 none !important;
}
.modal-footer {
    border: 0 none !important;
    outline: none !important;
    display: flex !important;
    position: relative;
    margin-bottom: 20px;
    /* padding-top: -100px !important; */
    height: 120px;
    margin-top: -60px;
    /* margin-top: -100px !important; */
    /* justify-content: center !important;
    align-items: center !important; */
}
.yellow-header{
    background-color: #fed000 !important;
}
.blue-modal-button {
    white-space: nowrap;
    display: inline-block !important;
    margin-top: -10px !important;
    margin-bottom: -10px !important;
    margin-left: 0px !important;
    justify-content: flex-end !important;
    align-items: stretch !important;
    border-radius: none !important;
    box-shadow: none !important;
    outline: none !important;
    /* outline-color: #00447c !important; */
    background-color: #00447c !important;
    font-weight: 500 !important;
    text-align: center !important;
    max-width: 400px !important;
    /* min-width: 100px !important; */
  }
.form-box {
    align-items: center;
    /* text-align: center; */
    margin-left: 0rem;
    margin-right: 0rem;
}
.orange-text {
  color:  orange;
  font-weight: bold;
  font-size: 2.0rem;
}
.label-format {
    font-size: 1.5rem;
}
.input-format {
    width: 90%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 1.5px 2px 2.5px 0px #00447c;
    white-space: pre-wrap;
}

#content input[disabled] {
    background-color: #ccc;
    padding: 2px;
    margin: 0 0 0 0;
    background-image: none;
}

.button-box {
    height: 100px;
    padding-bottom: -5px;
}

.blue-button {
    white-space: nowrap;
    display: inline-block !important;
    /* margin-top: 50px !important; */
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    /* padding-top: 8px !important; */
    padding-bottom: 8px !important;
    justify-content: flex-end !important;
    align-items: stretch !important;
    border-radius: 8px !important;
    background-color: #00447c !important;
    font-weight: 500 !important;
    text-align: center !important;
    max-width: 400px !important;
    min-width: 100px !important;
  }
  .blue-button:hover {
    background-color: #fed000 !important;
    color: #000;
    font-weight: 600;
  }
  .yellow-button {
    align-self: center;
    position: relative;
    display: inline-block !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    /* margin-left: 16rem !important; */
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    justify-content: flex-end !important;
    align-items: stretch !important;
    border-radius: 8px !important;
    background-color: #fed000 !important;
    color: #00447c !important;;
    font-weight: 500 !important;
    text-align: center !important;
    max-width: 200px !important;
    min-width: 100px !important;
  }
  .yellow-button:hover {
    background-color: #00447c !important;
    color: white !important;
    font-weight: 600;
  }
  /* .yellow-button:focus {
      background-color: #00603a;
      color: white;
      font-weight: 600;
  } */

  .display-icon {
    width: 40px;
    height: 40px;
  }

  .startDate {
    padding-right: 1.5rem;
  }

  .endDate {
    padding-right: 1.5rem;
  }

  .datePickerWrapper {
    color: #00447c;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }
  .applyFilter_icon {
    cursor: pointer;
  }
  .filtered-total {
    color: #00447c;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }
  .top-padding {
    margin-top: -20px;
  }

.green-text {
    color:  #00603a;
    font-weight: bold;
    font-size: 1.25rem;
}
.blue-text {
    color:  #00447c;
    /* font-weight: bold; */
    font-size: 1.25rem;
}
.big-blue-text {
    color:  #00447c;
    font-weight: 500;
    font-size: 2.25rem;
    margin-top: -30px;
    margin-bottom: 20px;
}
.billing-master-label-format {
    font-size: 1.25rem;
}
.billing-master-input-format {
    width: 90%;
    padding: 6px 8px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 1.5px 2px 2.5px 0px #00447c;
    white-space: pre-wrap;

}
.section-divider {
    color:  #00447c;
    font-weight: 700;
    font-size: 1.25rem;
    text-align: center;
    border-bottom: solid 2px #00447c;

}


.host-picture-format {
    border-top-left-radius: 50% 50% !important;
    border-top-right-radius: 50% 50% !important;
    border-bottom-right-radius: 50% 50% !important;
    border-bottom-left-radius: 50% 50% !important;
    position: relative;
    width: 200px !important;
    height: 200px !important;
    margin-top: -.5rem;
    margin-bottom: 1rem;
}
.green-text {
    color:  #00603a;
    font-weight: bold;
    font-size: 1.25rem;
}
.blue-text {
    color:  #00447c;
    /* font-weight: bold; */
    font-size: 1.25rem;
}
.host-label-format {
    font-size: 1.25rem;
}
.host-input-format {
    width: 90%;
    padding: 6px 8px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 1.5px 2px 2.5px 0px #00447c;
    white-space: pre-wrap;

}
.section-divider {
    color:  #00447c;
    font-weight: 700;
    font-size: 1.25rem;
    text-align: center;
    border-bottom: solid 2px #00447c;

}

.sidebar-menu-container {
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: 290px;
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    text-align: center;
    color: white;
    background-color: #00447c ;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 140px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 20px
}
a {
    color: white;
    text-decoration: none;
}
.menu-button {
    color: white;
    border: none !important;
    border-color: #00447c !important;
    margin-left: 0px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    background-color: #00447c !important;
    max-width: 280px !important;
    box-shadow: none !important;
}
.menu-button:hover {
    /* font-size: 105% !important; */
    font-weight: 600;
}
.menu-button:focus {
    outline: 0 !important;
    outline-color:  #00447c !important;
}
.menu-button:active {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}
.dropdown-menu-font {
    color: #00447c !important;
    font-weight: 600 !important;
}
#user-menu {
    right: 3em;
    width: 100%;
    color: white;
    text-align: center;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 0px;
}
@media (max-width: 1580px) {
    .sidebar-menu-container {
        width: 200px;
        padding-left: 10px;
        padding-right: 10px;
        margin-right: 10px;
        font-size: 90% !important;
    }
    .menu-button {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
        max-width: 280px !important;
        box-shadow: none !important;
        font-size: 90% !important;

    }
    .menu-button:hover {
        font-size: 92% !important;
    }
}
@media (max-width: 1480px) {
    .sidebar-menu-container {
        width: 150px;
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 0px;
        font-size: 90% !important;
    }
    .menu-button {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
        max-width: 280px !important;
        box-shadow: none !important;
        font-size: 90% !important;
    }
    .menu-button:hover {
        font-size: 91% !important;
    }
}
@media (max-width: 1300px) {
    .sidebar-menu-container {
        width: 120px;
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 0px;
        font-size: 80% !important;
    }
    .menu-button {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
        max-width: 280px !important;
        box-shadow: none !important;
        font-size: 80% !important;
    }
    .menu-button:hover {
        font-size: 80.05% !important;
    }
}

.form-background {
    background-color: white;
}
.banner {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
h1 {
    font-weight: 600 !important;
    font-size: 2.5vw !important;
    color: #00447c;
}
h3 {
    font-size: 2.0vw !important;
}
.card-style {
    padding-top: 120px;
    width: 100%;
    height: 100%;
    margin-left: -30px;
    align-items: center;
    border: none !important;
}
.card-body-style {
    text-align: center !important;
    align-items: center !important;
    width: 80rem;
    margin-top: -50px !important;
    /* margin-left: -0px !important; */
    padding-top: 0px !important;
    border: none !important
}
.form-title {
    font-size: 3rem;
    font-weight: bold;
    color: #00447c;
    text-align: center !important;
}
.Toastify__toast-body {
    font-weight: bold;
    color: #00447c;
}
@media (min-width: 1580px) {
    .banner {
        margin-left: 100px;
    }

}
/*
This is from the UE Host App repository - Used in the Host preview pages.
*/


#host-background {
    max-width: 100%;
    /* margin-top: 80px; */
    padding-bottom: 60px;
    background-color:  #00603a;
    border-style: solid;
    border-width: 1px;
    border-color: #004328;
}
.host-page-title-container {
    max-width: 90%;
    padding-top: 30px;
    padding-bottom: 60px;
    margin-top: 50px;
    background-color:  #00603a;
}

.loading-message-box {
    width: 20%;
    height: 40%;
}

.title-box {
    flex: 1 1 auto;
    text-align: center;
    align-items: center !important;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 100px;
    padding-right: 100px;
    border-color: #00447c;
    border-width: 5px;
    border-style: solid;
    background-color: white;
}
.trans-button {
    background-color: transparent;
    border: transparent;
    cursor: pointer;
}
.trans-button:hover {
    transform: scale(1.25);
    cursor: pointer;
    /* display: inline-block */
}
.blue-button {
    display: inline-block !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    justify-content: flex-end !important;
    align-items: stretch !important;
    border-radius: 8px !important;
    background-color: #00447c !important;
    font-weight: 500 !important;
    text-align: center !important;
  }
  .blue-button:hover {
    background-color: #fed000 !important;
    color: #000;
    font-weight: 600;
  }

.host-container {
    max-width: 100%;
    margin-top: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 40px;
    background-color: white;
    border-style: solid;
    border-width: 5px;
    border-color: #00447c;
}
.yellow-button {
    align-self: center;
    position: relative;
    display: inline-block !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    /* margin-left: 16rem !important; */
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    justify-content: flex-end !important;
    align-items: stretch !important;
    border-radius: 8px !important;
    background-color: #fed000 !important;
    color: #00447c !important;;
    font-weight: 500 !important;
    text-align: center !important;
  }
  .yellow-button:hover {
    background-color: #00447c !important;
    color: white !important;
    font-weight: 600;
  }

.host-card {
    align-items: center;
    text-align: center;
    border-color: #00447c!important;
    border-width: 5px !important;
    border-style: solid !important;
}
.host-photo {
    border-top-left-radius: 50% 50% !important;
    border-top-right-radius: 50% 50% !important;
    border-bottom-right-radius: 50% 50% !important;
    border-bottom-left-radius: 50% 50% !important;
    position: relative;
    width: 200px !important;
    height: 200px !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.host-name {
    font-weight: 700 !important;
    font-size: 2rem !important;
    font-weight: bold;
    color: #007848
}
.headline{
    font-size: 1.5rem !important;
    font-weight: bold;
}
.ue-icon {
    width: 30px;
    height: 30px;
}
.public-icon {
    width: 30px;
    height: 30px;
}
.card-footer {
    background-color: white !important;
    border: none !important;
}
@media (max-width: 992px) {
    h1 {
        font-size: 2.5rem !important;
    }
    h3 {
        font-size: 1.5rem !important;
    }
    .host-page-title-container {
        padding-top: 0px;
        padding-bottom: 40px;
        padding-left: 0rem;
        padding-right: 0rem;
        margin-top: 40px;
    }
}
@media (max-width: 780px) {
    #host-background {
        max-width: 100%;
        margin-top: 60px;
    }
    .host-page-title-container {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 0rem;
        padding-right: 0rem;
        margin-top: 40px;
        max-width: 98%;
    }
    .title-box {
        padding-left: 10px;
        padding-right: 10px;
    }
    h1 {
        font-size: 2rem !important;
    }
    h3 {
        font-size: 1.70rem !important;
    }

}
@media (max-width: 415px) {
    #host-background {
        padding-bottom: 20px;
    }

    .host-page-title-container {
        padding-left: .0rem;
        padding-right: .0rem;
        margin-top: 35px;
        padding-bottom: 10px;
        margin-bottom: -20px;
        background-color:  #00603a;
    }
    .host-card {
        width: 22rem !important;
    }
    h1 {
        font-size: 1.5rem !important;
    }
    h3 {
        font-size: 1.2rem !important;
    }
    h4 {
        font-size: .9rem !important;
    }

}
@media (max-width: 325px) {
    h1 {
        font-size: 1.25rem !important;
    }
    h3 {
        font-size: 1.1rem !important;
    }
    h4 {
        font-size: .8rem !important;
    }
    .host-card {
        width: 18rem !important;
    }
}

/* This is from UE Host app repository.  Used for styling in Host Previews. */

.host-photo-more {
    border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;
    align-self: center;
    position: relative;
    max-width: 70%;
    height: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.more-about-alignment {
    text-align: left;
    align-items: left;
}
.small-print {
    font-size: .8rem;
}
.ue-icon {
    width: 30px;
    height: 30px;
}
.public-icon {
    width: 30px;
    height: 30px;
}
.client-rating-text {
    padding-top: 2rem;
    font-size: 1.5rem;
}
.host-message {
    text-align: wrap;
}
.resume-icon {
    border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;
    -moz-box-shadow: none;
    -goog-ms-box-shadow: none;
    box-shadow: none;
    /* border-color: black;
    border-style: solid; */
    width: 45px;
    height: 45px;
    padding-top: -5px;
    margin-top: -5px;
    margin-left: -8px;
    margin-right: 14px;
}
.email-icon {
    /* margin-top: 25px; */
    margin-left: -12px;
    width: 50px;
    height: 50px;
    background-size: 5px 5px;
    /* viewBox: "0 0 50 50"; */
}
@media (max-width: 992px) {
    .host-photo-more {
        max-width: 80%;
    }
    .client-rating-text {
        padding-top: 0rem;
        font-size: 1.1rem;
    }
}


.RatingStars {
    font-family: sans-serif;
    text-align: center;
    color:  #fed000;
    font-size: 0px;
  }
.player-wrapper {
    position: relative;
    padding-top: 56.25%
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

