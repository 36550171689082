/*
This is from the UE Host App repository - Used in the Host preview pages.
*/


#host-background {
    max-width: 100%;
    /* margin-top: 80px; */
    padding-bottom: 60px;
    background-color:  #00603a;
    border-style: solid;
    border-width: 1px;
    border-color: #004328;
}
.host-page-title-container {
    max-width: 90%;
    padding-top: 30px;
    padding-bottom: 60px;
    margin-top: 50px;
    background-color:  #00603a;
}

.loading-message-box {
    width: 20%;
    height: 40%;
}

.title-box {
    flex: 1 1 auto;
    text-align: center;
    align-items: center !important;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 100px;
    padding-right: 100px;
    border-color: #00447c;
    border-width: 5px;
    border-style: solid;
    background-color: white;
}
.trans-button {
    background-color: transparent;
    border: transparent;
    cursor: pointer;
}
.trans-button:hover {
    transform: scale(1.25);
    cursor: pointer;
    /* display: inline-block */
}
.blue-button {
    display: inline-block !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
    border-radius: 8px !important;
    background-color: #00447c !important;
    font-weight: 500 !important;
    text-align: center !important;
  }
  .blue-button:hover {
    background-color: #fed000 !important;
    color: #000;
    font-weight: 600;
  }

.host-container {
    max-width: 100%;
    margin-top: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 40px;
    background-color: white;
    border-style: solid;
    border-width: 5px;
    border-color: #00447c;
}
.yellow-button {
    align-self: center;
    position: relative;
    display: inline-block !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    /* margin-left: 16rem !important; */
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
    border-radius: 8px !important;
    background-color: #fed000 !important;
    color: #00447c !important;;
    font-weight: 500 !important;
    text-align: center !important;
  }
  .yellow-button:hover {
    background-color: #00447c !important;
    color: white !important;
    font-weight: 600;
  }

.host-card {
    align-items: center;
    text-align: center;
    border-color: #00447c!important;
    border-width: 5px !important;
    border-style: solid !important;
}
.host-photo {
    border-top-left-radius: 50% 50% !important;
    border-top-right-radius: 50% 50% !important;
    border-bottom-right-radius: 50% 50% !important;
    border-bottom-left-radius: 50% 50% !important;
    position: relative;
    width: 200px !important;
    height: 200px !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.host-name {
    font-weight: 700 !important;
    font-size: 2rem !important;
    font-weight: bold;
    color: #007848
}
.headline{
    font-size: 1.5rem !important;
    font-weight: bold;
}
.ue-icon {
    width: 30px;
    height: 30px;
}
.public-icon {
    width: 30px;
    height: 30px;
}
.card-footer {
    background-color: white !important;
    border: none !important;
}
@media (max-width: 992px) {
    h1 {
        font-size: 2.5rem !important;
    }
    h3 {
        font-size: 1.5rem !important;
    }
    .host-page-title-container {
        padding-top: 0px;
        padding-bottom: 40px;
        padding-left: 0rem;
        padding-right: 0rem;
        margin-top: 40px;
    }
}
@media (max-width: 780px) {
    #host-background {
        max-width: 100%;
        margin-top: 60px;
    }
    .host-page-title-container {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 0rem;
        padding-right: 0rem;
        margin-top: 40px;
        max-width: 98%;
    }
    .title-box {
        padding-left: 10px;
        padding-right: 10px;
    }
    h1 {
        font-size: 2rem !important;
    }
    h3 {
        font-size: 1.70rem !important;
    }

}
@media (max-width: 415px) {
    #host-background {
        padding-bottom: 20px;
    }

    .host-page-title-container {
        padding-left: .0rem;
        padding-right: .0rem;
        margin-top: 35px;
        padding-bottom: 10px;
        margin-bottom: -20px;
        background-color:  #00603a;
    }
    .host-card {
        width: 22rem !important;
    }
    h1 {
        font-size: 1.5rem !important;
    }
    h3 {
        font-size: 1.2rem !important;
    }
    h4 {
        font-size: .9rem !important;
    }

}
@media (max-width: 325px) {
    h1 {
        font-size: 1.25rem !important;
    }
    h3 {
        font-size: 1.1rem !important;
    }
    h4 {
        font-size: .8rem !important;
    }
    .host-card {
        width: 18rem !important;
    }
}