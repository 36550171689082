@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet");

html {
  font-family: Montserrat, sans-serif;
  background-color: transparent;
}
body {
    font-family: "Montserrat";
    /* -apple-system, BlinkMacSystemFont; */
    color:#1e2022;
    background-color: white;
    text-align: center;
    width: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat",sans-serif;
  font-style: bold;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
